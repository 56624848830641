import { BrowserRouter, Route, Routes } from "react-router-dom";

import { BertolottiAIWorks, BertolottiIndustries, BertolottiRail, Home, InvestorRelator, CertificazioniSPA, CertificazioniAiWorks, SegnalazioniSA8000 } from "./pages";
import LanguageContextProvider from "./Context/LanguageContext";
import VideoPage from "./pages/VideoPage";

// * Import components GA4
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./css/style.css";
import ScrollToTop from "./components/ScrollToTop";
import CookieComponent from "./components/common/CookieComponent";
import CertificazioniRail from "./pages/CertificazioniRail";


ReactGA.initialize("G-V39QR98L4Y");

const EnsureTrailingSlash = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;
    // Se il percorso non termina con una barra e non è la root
    if (!path.endsWith('/') && path !== '/') {
      // Reindirizza alla versione del percorso con la barra finale
      navigate(`${path}/`, { replace: true });
    }
  }, [location, navigate]);

  return children;
};

function App() {
  return (
    <LanguageContextProvider>
      <BrowserRouter>
        <ScrollToTop />
        <CookieComponent />
        <EnsureTrailingSlash>
          <Routes>
              <Route path="*" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/investor-relations/" element={<InvestorRelator />} />
              <Route path="/bertolotti-industries/" element={<BertolottiIndustries />} />
              <Route path="/bertolotti-rail/" element={<BertolottiRail />} />
              <Route path="/bertolotti-aiworks/" element={<BertolottiAIWorks />} />
              <Route path="/certificazioni-bertolotti-spa/" element={<CertificazioniSPA />} />
              <Route path="/certificazioni-bertolotti-rail/" element={<CertificazioniRail />} />
              <Route path="/certificazioni-bertolotti-aiworks/" element={<CertificazioniAiWorks />} />
              <Route path="/segnalazioni-sa8000/" element={<SegnalazioniSA8000 />} />
              <Route path="/video/" element={<VideoPage link="/assets/video/gruppo_istituzionale_novoice.mp4" />} />
          </Routes>
        </EnsureTrailingSlash>
      </BrowserRouter>
    </LanguageContextProvider>
  );
}

export default App;
